'use strict';

/**
 * Adds parameters to the url
 * @param {string} url - base url either with or without parameters
 * @param {Object} params - key value pair to add as parameters to the url
 * @returns {string} full url string with parameters
 */
function addQueryParams(url, params) {
    const urlParts = url.split('?'); // Split the URL into [baseUrl, queryString]
    const baseUrl = urlParts[0];
    const queryString = urlParts[1] || ''; // If no query string, it's empty

    // Parse the existing query string into an object
    let queryParams = new URLSearchParams(queryString);

    // Add new parameters (or update existing ones)
    $.each(params, function (key, value) {
        queryParams.set(key, value); // Adds or updates the parameter
    });

    // Rebuild the full URL
    return baseUrl + '?' + queryParams.toString();
}

module.exports = {
    addQueryParams: addQueryParams
};
